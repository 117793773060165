import { graphql, StaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { Text } from "rebass"
import { InnerHtmlComponent } from "../components/InnerHtmlComponent"
import styles from "./Services.module.css"

const Section = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: flex-end;
  height: 100vh;
  width: 100%;
  background: linear-gradient(-6deg, #f8f8f8 70%, #fff 60%);
  padding-top: 10rem;
  justify-content: flex-end;
  -moz-box-align: center;
  padding-left: 5%;
    
  @media (max-width: 768px) {
    display: block;
    padding-top: 5rem;
    padding-left: 0;
    height: 100%;
  }
  
  @media (max-width: 480px) {
     justify-content: flex-start;
     padding-top: 5rem;
     padding-left: 0;
     height: 100vh;
  }
`
const StyledImage = styled.img`
    width: 100%;
    align-self: center;
    object-fit: cover;
    
    @media (max-width: 480px) {
      height: 35%;  
    }
`

const ServiceWrapper = styled.div`
  position: relative;
  width: 42%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  
  @media (max-width: 768px) {
    width: 100%;
  
    .service-text {
      font-size: 1.1rem;
      margin: 5% 15%;
      padding: 0;
    }
  }
  
  @media (max-width: 480px) {
    height: 90vh !important;
       
    .service-text {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40vh;
      font-size: 1.2em;
      margin: 5% auto 0;
      width: 80%;
      padding: 0;
    }
  }
  
  @media (max-width: 320px) {
     .service-text {
        font-size: 1.15em;
     }
  }
`

const ImageLabel = styled.span`
    display: block;
    position: absolute;
    background-color: rgb(246, 104, 47);
    top: 2%;
    left: 2%;
    padding: 8px;
`;

const Service = ({ serviceItems }) => {
  return serviceItems.map(({ node }, index) => {
    const imageUrl = node.desktopImage.file.url;
    const text = node.childContentfulServiceDescriptionContentTextNode.content;
    const title = node.title;

    return (
      <ServiceWrapper key={index} className="slide">
        <StyledImage src={`https:${imageUrl}`} alt="" />
        <ImageLabel>
          <Text fontFamily="IBM Plex Sans" color="white" fontSize="0.9rem">
            {InnerHtmlComponent(title)}
          </Text>
        </ImageLabel>

        <Text
          fontSize="1rem"
          fontFamily="IBM Plex Sans"
          fontWeight="300"
          textAlign="left"
          paddingRight="20%"
          marginTop="5%"
          color="solidDark"
          lineHeight="1.4"
          className={`${styles.servicesContent} service-text`}
        >
          {InnerHtmlComponent(text)}
        </Text>
      </ServiceWrapper>
    )
  })
}


const Services = ({ index }) => {

  return (
    <StaticQuery
      query={graphql`
      query ServicesNewQuery {
        allContentfulServiceDescription(
          filter: { node_locale: { eq: "en-US" } }
          sort: { fields: [updatedAt], order: ASC }
        ) {
          edges {
            node {
              id
              title
              header
              desktopImage {
                file {
                  url
                }
              }
              mobileImage {
                file {
                  url
                }
              }
              childContentfulServiceDescriptionContentTextNode {
                content
              }
            }
          }
        }
      }
    `}
      render={data => {
        const serviceItems = data.allContentfulServiceDescription.edges;

        return (
          <Section className="section" style={{ zIndex: index }} id="services-anchor">
              <Service serviceItems={serviceItems} />
          </Section>
        )
      }}
    />
  )
}

export default Services;